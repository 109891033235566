<template>
  <ui-list
    :heading="heading"
    :headings="[$tc('models.lesson', 1), $tc('models.mediatype', 1), '']"
    :items="lessons"
  >
    <template v-slot:default="props">
      <ui-list-data :text="getTranslation(props.item.name)" :info="''">
      </ui-list-data>

      <ui-list-data
        type="tag"
        :text="$t(`mediatype.${props.item.media.mediaType.name}`)"
      >
      </ui-list-data>

      <ui-list-data small>
        <ui-link
          type="text"
          size="small"
          uppercase
          @click="
            $router.push({
              name: 'lessons-edit',
              params: { id: props.item.id }
            })
          "
          >{{ $t('actions.edit') }}</ui-link
        >
        <ui-link
          type="danger"
          size="small"
          uppercase
          @click="onDelete(props.item)"
        >
          {{ $t('actions.delete') }}
        </ui-link>
      </ui-list-data>
    </template>
  </ui-list>
</template>

<script>
import UiLink from '@/components/ui/UiLink';
import UiList from '@/components/ui/UiList';
import UiListData from '@/components/ui/UiListData';

export default {
  props: {
    heading: {
      type: String
    },
    lessons: {
      type: Array,
      required: true
    }
  },

  components: {
    UiLink,
    UiList,
    UiListData
  },

  methods: {
    getTranslation(transList) {
      return transList.filter(t => t.lang === this.$i18n.locale)[0].value;
    },

    onDelete(lesson) {
      this.$emit('onDelete', lesson);
    }
  }
};
</script>
