<template>
  <transition name="fade">
    <div v-if="show" class="modal-container">
      <div class="w-full max-w-2xl">
        <ui-card>
          <p class="py-4">{{ text }}</p>
          <em v-if="info" class="py-4">{{ info }}</em>

          <template v-slot:footer>
            <div class="flex">
              <ui-link type="danger" @click="handleConfirm">{{
                confirmText
              }}</ui-link>
              <ui-link type="text" class="ml-4" @click="handleCancel">{{
                cancelText
              }}</ui-link>
            </div>
          </template>
        </ui-card>
      </div>
    </div>
  </transition>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import UiLink from '@/components/ui/UiLink';

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    info: {
      type: String,
      required: false,
      default: null
    },
    confirmText: {
      type: String,
      default: function() {
        return this.$t('actions.confirm');
      }
    },
    cancelText: {
      type: String,
      default: function() {
        return this.$t('actions.cancel');
      }
    }
  },

  components: {
    UiCard,
    UiLink
  },

  data() {
    return {};
  },

  methods: {
    handleConfirm() {
      this.$emit('confirm');
    },

    handleCancel() {
      this.$emit('cancel');
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-container {
  @apply fixed inset-0 flex items-center justify-center px-3 py-6;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

  @screen sm {
    @apply px-4 py-8;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
